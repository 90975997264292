import AutoSuggestWrapper from "components/auto_suggest_wrapper";
import { ListForm } from "components/renewaled_ui/form_views";
import React from "react";

/**
 * 選択欄で選択された際のパラメータ
 */
export interface OnSuggestionSelectedParams {
  /**
   * 選択された選択肢のオブジェクト
   */
  readonly suggestion: unknown;
  /**
   * 選択されたときの値
   */
  readonly suggestionValue: string;
  /**
   * 選択方法(click または enter)
   */
  readonly method: "click" | "enter";
}

interface Props {
  /**
   * ラベル
   */
  readonly label: string;
  /**
   * 必須入力かどうか
   */
  readonly isRequired: boolean;
  /**
   * 入力欄が編集可能かどうか
   */
  readonly editable: boolean;
  /**
   * 階層別の経費科目選択欄に入力中の文字列
   * 最上位の経費科目から順に格納される。
   */
  readonly nestedCategoryNames: string[];
  /**
   * 階層別の経費科目選択欄の選択候補
   * 最上位の経費科目から順に格納される。
   */
  readonly nestedCategories: {
    /**
     * 検索でフィルタされた経費科目の選択肢一覧
     */
    readonly current: unknown[];
  }[];
  /**
   * 1つの階層の経費科目選択欄で、文字列入力やクリアによって選択肢を更新する必要があった場合
   * @param value
   * @param index 階層のインデックス
   */
  readonly onSuggestionsUpdateRequestedRow: (
    updatedValue: string,
    index: number,
  ) => void;
  /**
   * 1つの階層の経費科目選択欄に文字を入力したとき
   * @param text 入力された文字
   * @param index 階層のインデックス(0が最上位の経費科目を示す)
   */
  readonly onTextChangedRow: (text: string, index: number) => void;
  /**
   * 1つの階層の経費科目で選択肢から選択したとき
   * @param e イベントオブジェクト
   * @param params
   * - suggestion 選択された経費科目
   * - suggestionValue 選択された経費科目の名前
   * - method 選択方法(click または enter)
   * @param index 階層のインデックス(0が最上位の経費科目を示す)
   */
  readonly onSuggestionSelectedRow: (
    e: React.FormEvent<HTMLInputElement>,
    params: OnSuggestionSelectedParams,
    index: number,
  ) => void;
  /**
   * 1つの階層の経費科目の選択欄をクリアしたとき
   * @param index 階層のインデックス(0が最上位の経費科目を示す)
   */
  readonly clearValueRow: (index: number) => void;
}

/**
 * 経費科目選択欄
 *
 * 経費科目の選択欄を描画します。
 * 経費科目には親子関係があり、親科目を選択すると子科目が表示されます。
 * 親科目から順に描画していきます。
 */
export const CategoryFormField: React.FC<Props> = ({
  label,
  isRequired,
  editable,
  nestedCategoryNames,
  nestedCategories,
  onSuggestionsUpdateRequestedRow,
  onTextChangedRow,
  onSuggestionSelectedRow,
  clearValueRow,
}) => {
  /**
   * 1つの階層の経費科目の選択欄を描画します。
   * @param value 現在の入力値
   * @param suggestions 経費科目の選択肢
   * @param disabled 入力欄が無効かどうか
   * @param inputPropsClassName 入力欄のクラス名
   * @param onSuggestionsUpdateRequested 経費科目の選択肢の更新を要求するコールバック関数
   * @param onTextChanged 入力値が変更されたときのコールバック関数
   * @param onSuggestionSelected 経費科目の選択肢が選択されたときのコールバック関数
   * @param clearValue 入力値をクリアするコールバック関数
   */
  const renderCategoryInput = (
    value: string,
    suggestions: unknown[],
    inputPropsClassName: string,
    disabled: boolean,
    onSuggestionsUpdateRequested: (updatedValue: string) => void,
    onTextChanged: (text: string) => void,
    onSuggestionSelected: (
      e: React.FormEvent<HTMLInputElement>,
      params: OnSuggestionSelectedParams,
    ) => void,
    clearValue: () => void,
  ): JSX.Element => {
    return (
      <AutoSuggestWrapper
        isImmutable
        inputProps={{ className: inputPropsClassName }}
        value={value}
        suggestions={suggestions}
        getSuggestionValue={(v): string => v.name}
        onSuggestionsUpdateRequested={onSuggestionsUpdateRequested}
        onTextChanged={onTextChanged}
        onSuggestionSelected={onSuggestionSelected}
        clearValue={clearValue}
        disabled={disabled}
      />
    );
  };

  /**
   * jQuery validation plugin の検証クラス名を算出します。
   *
   * 検証クラス名は jQuery validation plugin を利用して、フォーム送信の直前に入力内容を検証するための定義です。
   * f必須入力の場合、検証クラス名は "validate[required, funcCallRequired[validateCategory]]" となります。
   * required は必須入力かどうか、funcCallRequired[validateCategory] は validateCategory メソッドで検証することを示します。
   * validateCategory メソッドは utilities/validation.js に定義されています。
   */
  const validationClass = isRequired
    ? "validate[required, funcCallRequired[validateCategory]]"
    : "";
  const inputPropsClassName = `form-control ${validationClass} category-input-field`;

  const disabled = !editable;

  return (
    <div>
      {nestedCategories.map(({ current }, index): JSX.Element => {
        const rowLabel = index === 0 ? label : "";
        const value = nestedCategoryNames[index] || "";
        const suggestions = current || [];

        const onSuggestionsUpdateRequested = (updatedValue: string): void =>
          onSuggestionsUpdateRequestedRow(updatedValue, index);
        const onTextChanged = (text: string): void =>
          onTextChangedRow(text, index);
        const onSuggestionSelected = (
          e: React.FormEvent<HTMLInputElement>,
          params: OnSuggestionSelectedParams,
        ): void => onSuggestionSelectedRow(e, params, index);
        const clearValue = (): void => clearValueRow(index);

        return (
          <ListForm key={index} label={rowLabel} required={isRequired}>
            {renderCategoryInput(
              value,
              suggestions,
              inputPropsClassName,
              disabled,
              onSuggestionsUpdateRequested,
              onTextChanged,
              onSuggestionSelected,
              clearValue,
            )}
          </ListForm>
        );
      })}
    </div>
  );
};
